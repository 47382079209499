
import { defineComponent, ref, onMounted, watch } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiSalesOrders } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  CommonButtonType,
  CommonFormType,
  CommonHtmlType,
} from "@/core/directive/type/common";

export default defineComponent({
  name: "cancel-fulfillment-order-modal",
  components: {},
  props: {
    id: { type: Number, required: true },
    type: { type: String, required: true },
    verifiedNotice: { type: String, required: true },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<CommonFormType>(null);
    const validationRejectionRef = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonButtonType>(null);
    const loading = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      order_id: props.id,
      type: props.type,
      verified_notice: "",
    });

    const options = ref({});

    const rules = ref({
      verified_notice: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => props.id,
      (newValue) => {
        formData.value.order_id = newValue;
      }
    );

    watch(
      () => props.type,
      (newValue) => {
        formData.value.type = newValue;
      }
    );

    watch(
      () => props.verifiedNotice,
      (newValue) => {
        formData.value.verified_notice = newValue;
      }
    );

    const submit = () => {
      if (!loading.value) {
        if (!formRef.value) {
          return;
        }
        formRef.value.validate(async (valid: boolean) => {
          if (valid) {
            if (loading.value) return;
            if (submitButton.value) {
              submitButton.value.setAttribute("data-kt-indicator", "on");
            }
            loading.value = true;
            const { data } =
              await ApiSalesOrders.rejectedSalesOrderSpecification(
                formData.value
              );
            loading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");
            if (data.code == 0) {
              showFormSubmitSuccessMsg(() => {
                resetForm();
                hideModal(validationRejectionRef.value);
                emit("update-list");
              });
            } else {
              showServerErrorMsg(data);
            }
          } else {
            showValidateErrorMsg();
            return false;
          }
        });
      }
    };

    const handleDiscard = () => {
      hideModal(validationRejectionRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const updateList = () => {
      resetForm();
      hideModal(validationRejectionRef.value);
      emit("update-list");
    };

    onMounted(() => {
      modalHideListener(validationRejectionRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      validationRejectionRef,
      submitButton,
      options,
      submit,
      handleDiscard,
      resetForm,
      updateList,
    };
  },
});
