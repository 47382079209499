/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-30 15:27:36
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-10-27 14:47:38
 */
import { TaggingItem } from "../interface/common";
import { CommonLanguageType } from "../type/common";
import { InfluenceOrderType } from "../type/distribution";

/**
 * @description: 获取达人订单类型
 * @return TaggingItem[]
 */
export const getInfluenceOrderTypeOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("fulfillmentOrder.firstOrder"),
      value: String(InfluenceOrderType.FirstOrder),
    },
    {
      label: language("fulfillmentOrder.repurchase"),
      value: String(InfluenceOrderType.Repurchase),
    },
  ];
};

/**
 * @description: 获取达人订单类型(map)
 * @return Map<number, string>
 */
export const getInfluenceOrderTypeMap = (
  language: CommonLanguageType
): Map<string, string> => {
  const result = new Map([
    [
      String(InfluenceOrderType.FirstOrder),
      language("fulfillmentOrder.firstOrder"),
    ],
    [
      String(InfluenceOrderType.Repurchase),
      language("fulfillmentOrder.repurchase"),
    ],
  ]);
  return result;
};
