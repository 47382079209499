
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  getCurrentInstance,
  useAttrs,
  computed,
  unref,
  watch,
  nextTick,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  ApiBase,
  ApiSalesOrders,
  ApiChanel,
  ApiAuth,
  ApiFulfillmentOrders,
} from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  getMbAnnualLimitVerificationRadio,
  getPartialWholeShipmentOptions,
  getQaReportOptions,
  getSalesOrderDepositPaymentStatus,
  getSalesOrderPaymentStatus,
  getSalesOrderStatusMap,
} from "@/core/directive/function/salesOrder";
import {
  CommonDropdownSwtich,
  CommonRequiredSwtich,
  CommonSwtich,
} from "@/core/directive/type/common";
import {
  getInfluenceOrderTypeMap,
  getInfluenceOrderTypeOptions,
} from "@/core/directive/function/distribution";
import {
  commonBackToList,
  commonChangeDefaultDate,
  commonGoToPath,
  commonPreviewFile,
  formatDate,
  formatDateTime,
  setModuleBCN,
  testIDCard,
  testMobile,
} from "@/core/directive/function/common";
import { isAuditView } from "@/core/directive/function/order";
import {
  TaggingChildrenItem,
  TaggingItem,
} from "@/core/directive/interface/common";
import {
  SalesOrderOverview,
  salesOrderOverviewFormData,
  salesOrderProfillingFormData,
} from "@/core/directive/interface/salesOrder";
import JwtService from "@/core/services/JwtService";
import { ElUpload } from "element-plus";
import fileDownload from "js-file-download";
import { SalesOrderNewStatus } from "@/core/directive/type/salesOrder";
import ValidationRejectionModal from "./ValidationRejectionModal.vue";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import WindowResize from "@/components/layout/WindowResize.vue";

export default defineComponent({
  name: "sales-order-order-overview-sales-profilling",
  components: { ValidationRejectionModal, PermissionCommon, WindowResize },
  setup(props, context) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const formSalesOrderRef = ref<null | HTMLFormElement>(null);
    const formPimFulfillmentOrderRef = ref<null | HTMLFormElement>(null);
    const formPimOrderRef = ref<null | HTMLFormElement>(null);
    const formFulfillmentOrderRef = ref<null | HTMLFormElement>(null);
    const formSettlementOrderRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);
    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };
    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };
    const verifyButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs(); // 接收父路由参数
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref(Object.assign({}, salesOrderProfillingFormData));
    const salesOrderOverviewData = ref(
      Object.assign({}, salesOrderOverviewFormData)
    );

    const mobile_country_code = "+86";

    const rules = ref({});

    const salesOrderRules = ref({
      // "distribution.sales_manager": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "distribution.distribution_channel": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "distribution.distribution_model": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "priority_management.priority_handling": [
      //   {
      //     type: "string",
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value == "" &&
      //           formData.value.priority_management.high_priority_order ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "priority_management.priority_level": [
      //   {
      //     type: "string",
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value == "" &&
      //           formData.value.priority_management.high_priority_order ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
    });
    const pimOrderRules = ref({});

    const fulfillmentOrderRules = ref({});

    const pimFulfillmentOrderRules = ref({
      // "pim.definite_inventory_source": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value === "" &&
      //           formData.value.pim.minimum_inventory_request === 0 &&
      //           formData.value.pim.additional_pim_notice === "" &&
      //           formData.value.pim.package_requirement === "" &&
      //           formData.value.pim.additional_pim_service ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "pim.minimum_inventory_request": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value === 0 &&
      //           formData.value.pim.definite_inventory_source === "" &&
      //           formData.value.pim.additional_pim_notice === "" &&
      //           formData.value.pim.package_requirement === "" &&
      //           formData.value.pim.additional_pim_service ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "pim.additional_pim_notice": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value === "" &&
      //           formData.value.pim.definite_inventory_source === "" &&
      //           formData.value.pim.minimum_inventory_request === 0 &&
      //           formData.value.pim.package_requirement === "" &&
      //           formData.value.pim.additional_pim_service ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "pim.package_requirement": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value === "" &&
      //           formData.value.pim.definite_inventory_source === "" &&
      //           formData.value.pim.minimum_inventory_request === 0 &&
      //           formData.value.pim.additional_pim_notice === "" &&
      //           formData.value.pim.additional_pim_service ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "fulfillment.fulfillment_manager": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "fulfillment.mb_fulfillment_type": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "fulfillment.required_documents": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value.length === 0 &&
      //           formData.value.fulfillment.partial_shipment === "" &&
      //           formData.value.fulfillment.complete_order_manually ===
      //             options.value.CommonSwtich.InActive &&
      //           formData.value.fulfillment.packaging_service === "" &&
      //           formData.value.fulfillment.fulfillment_notice === "" &&
      //           formData.value.fulfillment.additional_fulfillment_service ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "fulfillment.packaging_service": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value === "" &&
      //           formData.value.fulfillment.partial_shipment === "" &&
      //           formData.value.fulfillment.complete_order_manually ===
      //             options.value.CommonSwtich.InActive &&
      //           formData.value.fulfillment.required_documents.length === 0 &&
      //           formData.value.fulfillment.fulfillment_notice === "" &&
      //           formData.value.fulfillment.additional_fulfillment_service ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "fulfillment.partial_shipment": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value === "" &&
      //           formData.value.fulfillment.complete_order_manually ===
      //             options.value.CommonSwtich.InActive &&
      //           formData.value.fulfillment.required_documents.length === 0 &&
      //           formData.value.fulfillment.fulfillment_notice === "" &&
      //           formData.value.fulfillment.packaging_service === "" &&
      //           formData.value.fulfillment.additional_fulfillment_service ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "fulfillment.complete_order_manually": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value === options.value.CommonSwtich.InActive &&
      //           formData.value.fulfillment.partial_shipment === "" &&
      //           formData.value.fulfillment.required_documents.length === 0 &&
      //           formData.value.fulfillment.fulfillment_notice === "" &&
      //           formData.value.fulfillment.packaging_service === "" &&
      //           formData.value.fulfillment.additional_fulfillment_service ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "fulfillment.fulfillment_notice": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value === "" &&
      //           formData.value.fulfillment.partial_shipment === "" &&
      //           formData.value.fulfillment.complete_order_manually ===
      //             options.value.CommonSwtich.InActive &&
      //           formData.value.fulfillment.required_documents.length === 0 &&
      //           formData.value.fulfillment.packaging_service === "" &&
      //           formData.value.fulfillment.additional_fulfillment_service ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
    });

    const settlementOrderRules = ref({
      // "settlement_fraud_controlling.settlement_type": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "settlement_fraud_controlling.settlement_mode": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "settlement_fraud_controlling.offline_payment_controller": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "settlement_bc_payment.payer": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value.trim() == "" &&
      //           formData.value.settlement_bc_payment.bc_custom_clearance ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "settlement_bc_payment.payer_identifier": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           value.trim() == "" &&
      //           formData.value.settlement_bc_payment.bc_custom_clearance ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "settlement_bc_payment.payer_identity_card_no": [
      //   {
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           !value &&
      //           formData.value.settlement_bc_payment.bc_custom_clearance ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("common.isRequired"));
      //         } else if (
      //           !testIDCard(value) &&
      //           formData.value.settlement_bc_payment.bc_custom_clearance ===
      //             options.value.CommonSwtich.Active
      //         ) {
      //           reject(t("salesOrder.pleasePayerIdentityCardNo"));
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      // "settlement_bc_payment.payer_mobile_phone": [
      //   {
      //     type: "number",
      //     asyncValidator: (rule, value) => {
      //       return new Promise<void>((resolve, reject) => {
      //         if (
      //           formData.value.settlement_bc_payment.bc_custom_clearance ===
      //           options.value.CommonSwtich.Active
      //         ) {
      //           if (!value) {
      //             reject(t("common.isRequired"));
      //           } else if (!testMobile(value)) {
      //             reject(t("common.enterMobile"));
      //           } else {
      //             resolve();
      //           }
      //         } else {
      //           resolve();
      //         }
      //       });
      //     },
      //   },
      // ],
      "settlement_tax_refund.business_date": [
        { required: true, message: t("common.isRequired"), trigger: "change" },
      ],
    });

    let defaultFormInfo = unref(formData.value);
    const isDisabledVerifyDistribution = ref(false);
    const isDisabledVerifyPim = ref(false);
    const isDisabledVerifyFulfillment = ref(false);
    const isDisabledVerifySettlement = ref(false);

    const isCanVerify = (type: string) => {
      let flag = true;
      switch (type) {
        case "distribution":
          if (
            // 分销信息判断
            defaultFormInfo.distribution.sales_manager === "" ||
            defaultFormInfo.distribution.distribution_channel === "" ||
            defaultFormInfo.distribution.distribution_model === "" ||
            (defaultFormInfo.priority_management.high_priority_order ===
              options.value.CommonSwtich.Active &&
              (defaultFormInfo.priority_management.priority_handling === "" ||
                defaultFormInfo.priority_management.priority_level === ""))
          ) {
            flag = false;
          }
          break;
        case "pim":
          if (
            // pim
            ((defaultFormInfo.pim.mb_order_type === "2" &&
              defaultFormInfo.pim.definite_inventory_source === "") ||
              defaultFormInfo.pim.mb_order_type === "1") &&
            defaultFormInfo.pim.minimum_inventory_request === 0 &&
            defaultFormInfo.pim.additional_pim_notice === "" &&
            defaultFormInfo.pim.package_requirement === "" &&
            defaultFormInfo.pim.additional_pim_service ===
              options.value.CommonSwtich.Active
          ) {
            flag = false;
          }
          break;
        case "fulfillment":
          if (
            // 履约信息判断
            defaultFormInfo.fulfillment.fulfillment_manager === "" ||
            defaultFormInfo.fulfillment.mb_fulfillment_type === "" ||
            (defaultFormInfo.fulfillment.required_documents.length === 0 &&
              defaultFormInfo.fulfillment.partial_shipment === "" &&
              defaultFormInfo.fulfillment.complete_order_manually ===
                options.value.CommonSwtich.InActive &&
              defaultFormInfo.fulfillment.packaging_service === "" &&
              defaultFormInfo.fulfillment.fulfillment_notice === "" &&
              defaultFormInfo.fulfillment.additional_fulfillment_service ===
                options.value.CommonSwtich.Active)
          ) {
            flag = false;
          }
          break;
        case "settlement":
          if (
            // 结算信息判断
            defaultFormInfo.settlement_fraud_controlling.settlement_type ===
              "" ||
            defaultFormInfo.settlement_fraud_controlling.settlement_mode ===
              "" ||
            ((defaultFormInfo.settlement_bc_payment.payer === "" ||
              defaultFormInfo.settlement_bc_payment.payer_identifier === "" ||
              !testIDCard(
                defaultFormInfo.settlement_bc_payment.payer_identity_card_no
              ) ||
              !testMobile(
                defaultFormInfo.settlement_bc_payment.payer_mobile_phone
              )) &&
              defaultFormInfo.settlement_bc_payment.bc_custom_clearance ===
                options.value.CommonSwtich.Active)
          ) {
            flag = false;
          }
          break;
        default:
          break;
      }
      return flag;
    };

    const options = ref({
      SalesOrderNewStatus: SalesOrderNewStatus,
      defaultTime: moment(moment(new Date()).format("YYYY-MM-DD"))
        .add(12, "hours")
        .toISOString(),
      CommonSwtich: CommonSwtich,
      CommonDropdownSwtich: CommonDropdownSwtich,
      CommonRequiredSwtich: CommonRequiredSwtich,
      partial_shipment: [] as TaggingItem[],
      qa_report: getQaReportOptions(t),
      partial_whole_shipment: getPartialWholeShipmentOptions(t),
      tax_duties_terms: [] as TaggingItem[],
      settlement_type: [] as TaggingItem[],
      settlement_mode: [] as TaggingItem[],
      mb_annual_limit_verification: getMbAnnualLimitVerificationRadio(t),
      priority_handling: [] as TaggingItem[],
      influence_order_type: getInfluenceOrderTypeOptions(t),
      influenceOrderTypeMap: getInfluenceOrderTypeMap(t),
      mb_order_type: [] as TaggingItem[],
      mb_fulfillment_type: [] as TaggingChildrenItem[],
      priority_level: [] as TaggingItem[],
      required_documents: [] as TaggingItem[],
      additional_notification: [] as TaggingItem[],
      packaging_service: [] as TaggingItem[],
      photographing_service: [] as TaggingItem[],
      exception_risk_level: [] as TaggingItem[],
      definite_inventory_source: [] as TaggingItem[],
      membership_group: [] as TaggingItem[],
      distribution_channel: [] as TaggingChildrenItem[],
      distribution_model: [] as TaggingItem[],
      mb_segmentation: [] as TaggingItem[],
      shipment: [],
      fulfillment_managerLoading: false,
      fulfillment_manager: [] as TaggingItem[],
      sales_managerLoading: false,
      sales_manager: [] as TaggingItem[],
      pim_managerLoading: false,
      pim_manager: [] as TaggingItem[],
      offline_payment_controllerLoading: false,
      offline_payment_controller: [] as TaggingItem[],
      credit_risk_control: [] as TaggingItem[],
    });

    const approve_order_processing_user = ref<string>("");
    const approve_overseas_shipment_user = ref<string>("");
    const approve_final_shipment_user = ref<string>("");

    watch(
      () => attrs.loading,
      (newValue) => {
        const data = attrs.detailData as SalesOrderOverview;
        if (data) {
          salesOrderOverviewData.value = data;
        }
      }
    );

    const getUserData = (type: number, id: number) => {
      ApiAuth.getUserInfo({
        id: id,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            if (type == 3) {
              approve_order_processing_user.value =
                moment(
                  formData.value.settlement_fraud_controlling
                    .approve_order_processing_date
                ).format("DD.MM.YYYY") +
                "," +
                data.data.username;
            } else if (type == 4) {
              approve_overseas_shipment_user.value =
                moment(
                  formData.value.settlement_fraud_controlling
                    .approve_overseas_shipment_date
                ).format("DD.MM.YYYY") +
                "," +
                data.data.username;
            } else if (type == 5) {
              approve_final_shipment_user.value =
                moment(
                  formData.value.settlement_fraud_controlling
                    .approve_final_shipment_date
                ).format("DD.MM.YYYY") +
                "," +
                data.data.username;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (options.value.sales_managerLoading = true)
            : (options.value.sales_managerLoading = false);
          break;
        case 1:
          status
            ? (options.value.fulfillment_managerLoading = true)
            : (options.value.fulfillment_managerLoading = false);
          break;
        case 2:
          status
            ? (options.value.offline_payment_controllerLoading = true)
            : (options.value.offline_payment_controllerLoading = false);
          break;
        case 3:
          status
            ? (options.value.pim_managerLoading = true)
            : (options.value.pim_managerLoading = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          options.value.sales_manager = data;
          break;
        case 1:
          options.value.fulfillment_manager = data;
          break;
        case 2:
          options.value.offline_payment_controller = data;
          break;
        case 3:
          options.value.pim_manager = data;
          break;
        default:
          break;
      }
    };

    const getMangersList = async (type, search?: string, id?: string) => {
      switchUserType(type, true);
      let params =
        search != ""
          ? {
              search_value: search,
              status: 0,
            }
          : {
              id: id,
              status: 0,
            };
      const { data } = await ApiBase.getUserSourceData(params);
      switchUserType(type, false);
      if (data.code == 0) {
        if (data.data.length > 0) {
          switchUserValue(type, data.data);
        }
      }
    };
    const debounceMangerSearch = _.debounce(getMangersList, 1000);
    const searchSalesItems = (query: string) => {
      debounceMangerSearch(0, query);
    };
    const searchOrderItems = (query: string) => {
      debounceMangerSearch(1, query);
    };
    const searchPaymentItems = (query: string) => {
      debounceMangerSearch(2, query);
    };
    const searchPimItems = (query: string) => {
      debounceMangerSearch(3, query);
    };

    const getSalesOrderApprove = (type: string) => {
      let textInfo = "";
      switch (type) {
        case "order_processing":
          textInfo =
            "Are you sure you would like to approve your Approve Order Processing?";
          break;
        case "overseas_shipment":
          textInfo =
            "Are you sure you would like to approve your Approve Overseas Shipment?";
          break;
        case "final_shipment":
          textInfo =
            "Are you sure you would like to approve your Approve final Shipment?";
          break;
      }
      Swal.fire({
        text: textInfo,
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiSalesOrders.getSalesOrderApprove({
            order_id: route.params.id,
            approve_type: type,
          });
          loading.value = false;
          if (data.code == 0) {
            getSalesOrderSpecificationData(true);
          }
        }
      });
    };

    const getSalesOrderSpecificationData = async (isNew = false) => {
      if (isNew) loading.value = true;
      const { data } = await ApiSalesOrders.getSalesOrderSpecificationData({
        order_id: route.params.id,
      });
      if (isNew) loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        // Object.keys(formData.value).forEach((item) => {
        //   options.value.defaultFormInfo[item] = formData.value[item];
        // });
        defaultFormInfo = data.data;
        if (formData.value.quotation_information.file_id != 0) {
          uploadData.value.file_id = [
            {
              name: formData.value.quotation_information.file_name,
              url: "",
              uid: formData.value.quotation_information.time,
            },
          ];
        }
        // if (data.data.distribution.membership_group != "") {
        //   getGroupLevelList("", data.data.distribution.membership_group);
        // }
        if (data.data.distribution.sales_manager !== "0") {
          getMangersList(0, "", data.data.distribution.sales_manager);
        } else {
          options.value.sales_manager = [
            {
              value: "0",
              label: t("salesOrderOverview.byCustomer"),
            },
          ];
        }
        if (data.data.fulfillment.fulfillment_manager != "") {
          getMangersList(1, "", data.data.fulfillment.fulfillment_manager);
        }
        if (
          data.data.settlement_fraud_controlling.offline_payment_controller !=
          ""
        ) {
          getMangersList(
            2,
            "",
            data.data.settlement_fraud_controlling.offline_payment_controller
          );
        }
        if (data.data.pim.pim_manager != "") {
          options.value.pim_manager = [
            {
              value: data.data.pim.pim_manager,
              label: data.data.pim.__show.pim_manager,
            },
          ];
        }
        // if (
        //   data.data.settlement_fraud_controlling
        //     .approve_order_processing_user != 0
        // ) {
        //   getUserData(
        //     3,
        //     data.data.settlement_fraud_controlling.approve_order_processing_user
        //   );
        // }
        // if (
        //   data.data.settlement_fraud_controlling
        //     .approve_overseas_shipment_user != 0
        // ) {
        //   getUserData(
        //     4,
        //     data.data.settlement_fraud_controlling
        //       .approve_overseas_shipment_user
        //   );
        // }
        // if (
        //   data.data.settlement_fraud_controlling.approve_final_shipment_user !=
        //   0
        // ) {
        //   getUserData(
        //     5,
        //     data.data.settlement_fraud_controlling.approve_final_shipment_user
        //   );
        // }
      }
    };

    const mbOrderTypeChange = (e) => {
      formData.value.pim.definite_inventory_source = "";
    };

    const getFileUrl = computed(() => {
      formData.value.payment_voucher.map((item) => {
        return item.url;
      });
      return formData.value.payment_voucher.map((item) => {
        return item.url;
      });
    });

    const previewProofOfPayment = (index) => {
      currentInstance.proxy.emitter.emit("preview-images", {
        sources: getFileUrl.value,
        sourcesIndex: index,
        sourcesSlide: index + 1,
        toggler: true,
      });
    };

    const getGroupLevelList = async (value?: string, id?: string) => {
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getInfluencerGroupSourceData(params);
      if (data.code == 0) {
        options.value.membership_group = data.data;
      }
    };
    const getChannelList = async () => {
      const { data } = await ApiChanel.getDistributionDataSource({});
      if (data.code == 0) {
        options.value.distribution_channel = data.data;
      }
    };

    const getFulfillmentModeSource = async () => {
      const { data } = await ApiFulfillmentOrders.getFulfillmentModeSource({
        order_id: route.params.id,
      });
      if (data.code == 0) {
        options.value.mb_fulfillment_type = data.data;
      }
    };

    const isHasChildrenTagging = computed(() => {
      const flag = options.value.mb_fulfillment_type.find(
        (item) => item.children
      );
      return flag;
    });

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "sales_order_shipping_mode",
          "sales_order_priority_level",
          "sales_order_distribution_mode",
          "required_documents_tagging",
          "exception_risk_level",
          "definite_inventory_source",
          "fulfillment_packaging_service",
          "sales_specification_mb_segmentation",
          "sales_specification_credit_risk_control",
          "sales_order_settlement_type",
          "sales_order_setttlement_mode",
          "sales_order_priority_handling",
          "sales_profilling_mb_order_type",
          "customer_clearance_mode",
        ],
      });
      if (data.code == 0) {
        options.value.mb_order_type =
          data.data.sales_profilling_mb_order_type.items;
        options.value.priority_level =
          data.data.sales_order_priority_level.items;
        options.value.required_documents =
          data.data.required_documents_tagging.items;
        options.value.exception_risk_level =
          data.data.exception_risk_level.items;
        options.value.definite_inventory_source =
          data.data.definite_inventory_source.items;
        options.value.packaging_service =
          data.data.fulfillment_packaging_service.items;
        options.value.distribution_model =
          data.data.sales_order_distribution_mode.items;
        options.value.settlement_type =
          data.data.sales_order_settlement_type.items;
        options.value.settlement_mode =
          data.data.sales_order_setttlement_mode.items;
        options.value.mb_segmentation =
          data.data.sales_specification_mb_segmentation.items;
        options.value.credit_risk_control =
          data.data.sales_specification_credit_risk_control.items;
        options.value.priority_handling =
          data.data.sales_order_priority_handling.items;
        options.value.tax_duties_terms =
          data.data.customer_clearance_mode.items;
        options.value.partial_shipment =
          data.data.sales_order_shipping_mode.items;
      }
    };

    const currentInstance: any = getCurrentInstance();
    const uploadUrl = ApiSalesOrders.uploadUrl();
    const uploadRefs = reactive({
      file_id: ref<InstanceType<typeof ElUpload>>(),
    });
    const id = route.params.id;
    const informationType = ref("");
    const verifiedNotice = ref("");
    const removeFlag = ref(false);
    let childWin: any;

    const uploadDisabled = ref({
      file_id: false,
    });

    const uploadData = ref({
      file_id: [] as any[],
    });

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    const handleProgress = (e, file, fileList, attributes) => {
      uploadDisabled.value[attributes] = true;
    };

    const handleSuccess = (response, file, fileList, sign, data) => {
      uploadDisabled.value[sign] = false;
      if (response.code !== 0) {
        const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
        if (uploadData.value[sign].length == 0) {
          refModal!.handleRemove(file, file.raw);
        } else {
          let params = {
            ...uploadData.value[sign][0],
            uid: moment().valueOf(),
          };
          refModal!.clearFiles();
          refModal!.handleStart(params);
        }
        Swal.fire({
          text: t("salesOrder.customerReferenceUploadError"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
      uploadData.value[sign] = fileList;
      data[sign] = response.data.file_id;
      data[sign.replace(/id/, "name")] = file.name;
      submitSalesOrder();
    };

    const handleError = (err, file, fileList, attributes) => {
      if (err) {
        uploadDisabled.value[attributes] = false;
        Swal.fire({
          text: t("common.uploadErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    const handleExceed = (files, fileList, sign) => {
      const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
      const file = files[0];
      refModal!.clearFiles();
      refModal!.handleStart(file);
      refModal!.submit();
    };

    const handleRemove = (file, fileList, sign, data, options, removeFlag) => {
      const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
      // 上传文件超出限制会在页面上删除文件，这里重新将之前的文件回显
      if (removeFlag && data[sign]) {
        refModal!.handleStart(options[sign][0]);
        return;
      }
      data[sign] = 0;
      data[sign.replace(/id/, "name")] = "";
      uploadData.value.file_id = [];
    };

    const handleDeleteUpload = (sign, data) => {
      const refModal = currentInstance.ctx._.refs[`uploadRefs.${sign}`];
      if (uploadData.value[sign].length > 0) refModal!.clearFiles();
      data[sign] = 0;
      data[sign.replace(/id/, "name")] = "";
      submitSalesOrder();
    };

    const handlePreview = (sign, form) => {
      const name = sign;
      uploadDisabled.value[sign] = true;
      ApiBase.getFile({
        id: form[sign],
      })
        .then(({ data }) => {
          uploadDisabled.value[sign] = false;
          const filename = attrs.userAccount
            ? moment(formData.value.created_at).format("YYYY.MM.DD") +
              "_" +
              attrs.userAccount +
              "_报价单" +
              route.params.id +
              ".xlsx"
            : form[sign.replace(/id/, "name")];
          fileDownload(data, filename);
          // let fileURL;
          // // var blob = new Blob([data], { type: "application/pdf" });
          // if (window.webkitURL != undefined) {
          //   // webkit or chrome
          //   fileURL = window.webkitURL.createObjectURL(data);
          // } else if (window.URL != undefined) {
          //   // mozilla(firefox)
          //   fileURL = window.URL.createObjectURL(data);
          // }
          // // let url = encodeURIComponent(fileURL);
          // childWin = window.open(
          //   fileURL,
          //   name,
          //   "height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
          // );
          // childWin.onload = function () {
          //   // childWin.print();
          //   if (window.webkitURL != undefined) {
          //     // webkit or chrome
          //     window.webkitURL.revokeObjectURL(fileURL);
          //   } else if (window.URL != undefined) {
          //     // mozilla(firefox)
          //     window.URL.revokeObjectURL(fileURL);
          //   }
          // };
        })
        .catch((error) => {
          uploadDisabled.value[sign] = false;
          console.log(error);
        });
    };

    const handleBeforeUpload = (file) => {
      const limit = 1024 * 1024 * 5;
      if (file.size > limit) {
        Swal.fire({
          text: t("common.uploadSizeErrors"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          removeFlag.value = false;
        });
        removeFlag.value = true;
        return false;
      }
      return true;
    };

    const cancelReadonly = (async) => {
      removeReadonly(async);
    };

    const removeReadonly = (async) => {
      nextTick(() => {
        if (!async) {
          // ios 手机有延迟问题
          setTimeout(() => {
            Object.keys(currentInstance.ctx._.refs).forEach((item) => {
              if (item.match("select")) {
                currentInstance.ctx._.refs[item]?.$el
                  .querySelector(".el-input__inner")
                  .removeAttribute("readonly");
              }
            });
          }, 200);
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          setSubmitAttribute();
          const { data } = await ApiSalesOrders.updateSalesOrderSpecification({
            order_id: route.params.id,
            distribution: formData.value.distribution,
            priority_management: formData.value.priority_management,
            quotation_information: formData.value.quotation_information,
            pim: formData.value.pim,
            fulfillment: formData.value.fulfillment,
            settlement_bc_payment: formData.value.settlement_bc_payment,
            settlement_fraud_controlling:
              formData.value.settlement_fraud_controlling,
            settlement_tax_refund: formData.value.settlement_tax_refund,
          });
          loading.value = false;
          setSubmitAttribute(false);
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              refreshInfo();
            });
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitSalesOrder = () => {
      if (!formSalesOrderRef.value) {
        return;
      }
      formSalesOrderRef.value.validate(async (valid: boolean) => {
        if (valid) {
          updateSalesOrder();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitPimFulfillmentOrder = () => {
      if (!formPimFulfillmentOrderRef.value) {
        return;
      }
      formPimFulfillmentOrderRef.value.validate((valid: boolean) => {
        if (valid) {
          updatePimFulfillmentOrder();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitPimOrder = () => {
      if (!formPimOrderRef.value) {
        return;
      }
      formPimOrderRef.value.validate((valid: boolean) => {
        if (valid) {
          updatePimOrder();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitFulfillmentOrder = () => {
      if (!formFulfillmentOrderRef.value) {
        return;
      }
      formFulfillmentOrderRef.value.validate((valid: boolean) => {
        if (valid) {
          updateFulfillmentOrder();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitSettlementOrder = () => {
      if (!formSettlementOrderRef.value) {
        return;
      }
      formSettlementOrderRef.value.validate((valid: boolean) => {
        if (valid) {
          updateSettlementOrder();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const updateSalesOrder = async () => {
      if (loading.value) return;
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiSalesOrders.updateSalesOrderSpecification({
        order_id: route.params.id,
        distribution: formData.value.distribution,
        priority_management: formData.value.priority_management,
        quotation_information: formData.value.quotation_information,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          refreshInfo();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updatePimFulfillmentOrder = async () => {
      if (loading.value) return;
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiSalesOrders.updateSalesOrderSpecification({
        order_id: route.params.id,
        pim: formData.value.pim,
        fulfillment: formData.value.fulfillment,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          refreshInfo();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updatePimOrder = async () => {
      if (loading.value) return;
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiSalesOrders.updateSalesOrderSpecification({
        order_id: route.params.id,
        pim: formData.value.pim,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          refreshInfo();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateFulfillmentOrder = async () => {
      if (loading.value) return;
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiSalesOrders.updateSalesOrderSpecification({
        order_id: route.params.id,
        fulfillment: formData.value.fulfillment,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          refreshInfo();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateSettlementOrder = async () => {
      if (loading.value) return;
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiSalesOrders.updateSalesOrderSpecification({
        order_id: route.params.id,
        settlement_bc_payment: formData.value.settlement_bc_payment,
        settlement_fraud_controlling:
          formData.value.settlement_fraud_controlling,
        settlement_tax_refund: formData.value.settlement_tax_refund,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          refreshInfo();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const verifyOrder = (type: string) => {
      let flag = true;
      switch (type) {
        case "distribution":
          flag = isDisabledVerifyDistribution.value;
          break;
        case "pim":
          flag = isDisabledVerifyPim.value;
          break;
        case "fulfillment":
          flag = isDisabledVerifyFulfillment.value;
          break;
        case "settlement":
          flag = isDisabledVerifySettlement.value;
          break;
        default:
          break;
      }
      if (!flag) {
        Swal.fire({
          text: t("salesOrder.verifyError"),
          icon: "warning",
          showCancelButton: false,
          buttonsStyling: false,
          confirmButtonText: t("common.yes"),
          cancelButtonText: t("common.no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            // console.log('');
          }
        });
        return false;
      }
      Swal.fire({
        text: t("salesOrder.verifyTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (verifyButton.value) {
            verifyButton.value.setAttribute("data-kt-indicator", "on");
          }
          const { data } = await ApiSalesOrders.verifiedSalesOrderSpecification(
            {
              order_id: route.params.id,
              type: type,
            }
          );
          verifyButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            Swal.fire({
              text: t("salesOrder.verifySuccess"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.okGotIt"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(async (result) => {
              if (result.isConfirmed) {
                refreshInfo();
              }
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const validationRejectOrder = (type: string, value: string) => {
      informationType.value = type;
      verifiedNotice.value = value;
    };

    const handleResetVerifiedNotice = () => {
      verifiedNotice.value = "";
    };

    const orderDepositPaymentStatus = computed(() => {
      return (status: number) => {
        return getSalesOrderDepositPaymentStatus(status, t);
      };
    });

    const paymentStatus = computed(() => {
      return (status: string) => {
        return getSalesOrderPaymentStatus(status, t);
      };
    });

    const backToList = () => {
      commonBackToList(router, "/sales-order/order-overview");
    };

    const refreshInfo = () => {
      loading.value = true;
      Promise.all([getSalesOrderSpecificationData()])
        .then(() => {
          loading.value = false;
          isDisabledVerifyDistribution.value = isCanVerify("distribution");
          isDisabledVerifyPim.value = isCanVerify("pim");
          isDisabledVerifyFulfillment.value = isCanVerify("fulfillment");
          isDisabledVerifySettlement.value = isCanVerify("settlement");
          context.emit("getFormData");
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([
        getDropdownOptions(),
        getChannelList(),
        getSalesOrderSpecificationData(),
        getFulfillmentModeSource(),
      ])
        .then(() => {
          loading.value = false;
          isDisabledVerifyDistribution.value = isCanVerify("distribution");
          isDisabledVerifyPim.value = isCanVerify("pim");
          isDisabledVerifyFulfillment.value = isCanVerify("fulfillment");
          isDisabledVerifySettlement.value = isCanVerify("settlement");
          cancelReadonly(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const businessDateDisabledDate = (time: Date) => {
      const startDate = new Date(
        formData.value.settlement_tax_refund.last_billing_date
      );
      return (
        time.getTime() > Date.now() ||
        time.getTime() <= startDate.getTime() - 86400000
      );
    };

    const isDoubleSale = computed(() => {
      return route.path.includes("double-sale-order");
    });

    onBeforeMount(() => {
      getFormInfo();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
      if (!attrs.loading) {
        const data = attrs.detailData as SalesOrderOverview;
        if (data) {
          salesOrderOverviewData.value = data;
        }
      }
    });

    return {
      attrs,
      t,
      formatDate,
      formatDateTime,
      commonGoToPath,
      commonChangeDefaultDate,
      getSalesOrderStatusMap,
      loading,
      formData,
      salesOrderOverviewData,
      defaultFormInfo,
      options,
      mobile_country_code,
      rules,
      salesOrderRules,
      pimFulfillmentOrderRules,
      pimOrderRules,
      fulfillmentOrderRules,
      settlementOrderRules,
      formRef,
      formSalesOrderRef,
      formPimFulfillmentOrderRef,
      formPimOrderRef,
      formFulfillmentOrderRef,
      formSettlementOrderRef,
      submitButton,
      verifyButton,
      approve_order_processing_user,
      approve_overseas_shipment_user,
      approve_final_shipment_user,
      refreshInfo,
      getFormInfo,
      searchSalesItems,
      searchOrderItems,
      searchPaymentItems,
      searchPimItems,
      getSalesOrderApprove,
      backToList,
      submit,
      submitSalesOrder,
      submitPimFulfillmentOrder,
      submitPimOrder,
      submitFulfillmentOrder,
      submitSettlementOrder,
      getSalesOrderSpecificationData,
      cancelReadonly,
      removeFlag,
      uploadRefs,
      uploadHeaders,
      isHasChildrenTagging,
      id,
      informationType,
      verifiedNotice,
      uploadUrl,
      uploadDisabled,
      uploadData,
      handleProgress,
      handleSuccess,
      handleError,
      handleExceed,
      handleRemove,
      handleDeleteUpload,
      handleBeforeUpload,
      handlePreview,
      mbOrderTypeChange,
      previewProofOfPayment,
      verifyOrder,
      validationRejectOrder,
      handleResetVerifiedNotice,
      orderDepositPaymentStatus,
      paymentStatus,
      businessDateDisabledDate,
      isDoubleSale,
      isAuditView,
    };
  },
});
