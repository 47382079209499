/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-30 15:27:36
 * @LastEditors: xiang.gao
 * @LastEditTime: 2022-06-07 15:59:46
 */
export enum InfluenceOrderType {
  FirstOrder = 1,
  Repurchase,
}
